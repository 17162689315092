import PageListing from '../components/PageListing'
import { graphql } from 'gatsby'

export default PageListing

export const query = graphql`
  query PageListing($slug: String!, $categories: [String!]) {
    site {
      siteMetadata {
        title
      }
    }
    listing: mdx(fields: { slug: { eq: $slug } }) {
      timeToRead
      body
      frontmatter {
        categories
        city
        business_license
        icon {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        image2 {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        image3 {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        image4 {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        image5 {
          childImageSharp {
            gatsbyImageData(height: 500, layout: CONSTRAINED)
          }
        }
        email
        id
        meta {
          title
        }
        post_tag
        post_title
        sitter_first_name
        sitter_last_name
        sitter_full_name
        state
        street_address
      }
    }
    listings: allMdx(
      limit: 50
      filter: {
        fields: {
          sourceName: { eq: "listings" }
          categories: { in: $categories }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            categories
            city
            id
            image {
              childImageSharp {
                gatsbyImageData(width: 250, layout: CONSTRAINED)
              }
            }
            street_address
            post_title
            post_tag
            tags
          }
        }
      }
    }
  }
`
