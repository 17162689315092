export const ScheduleEstimate = [
  'All day',
  'Every 2 hours',
  'Every 4 hours',
  'Every 8 hours',
]

export const HomeCriteria = [
  'My home has a fully-fenced backyard',
  'My townhome or apartment has a balcony',
  'I allow pets on indoor furniture',
  'I allow pets on outdoor furniture only',
  'I have a doggy door',
  'I have a pool in my backyard',
  'I work from home',
  'Watching dogs is my full-time job',
  'I work part-time',
  'I work in an office, but come home for lunch',
  'I work in an office that allows dogs',
  'My house has live, indoor, potted plants',
]

export const DogCount = [
  '1 dog',
  '2 dogs',
  '3 dogs',
  'I can host more than 3 dogs at once',
]

export const DogCriteria = [
  'Small dogs (5-20lbs)',
  'Medium dogs (21-50lbs)',
  'Large dogs (51-100lbs)',
  'Giant dogs (100lbs+)',
  'Spayed females',
  'Unspayed females',
  'Neutered males',
  'Unneutered males',
  'Puppies',
  'Senior dogs',
  'Dogs in wheelchairs or who require prosthetics',
  'Dog-aggressive or reactive dogs',
  'Dogs that cannot be around cats or small animals',
  'Dogs with separation anxiety',
  'Dogs who are not housetrained or are incontinent',
  'Dogs with light medication needs',
  'Dogs with advanced medication needs',
  'Dogs that have seizures',
  'Dogs with arthritis',
  'Dogs that shed a lot',
  'Working dogs or high-energy dogs',
]

export const ExerciseEstimate = [
  '20 minutes per day',
  '30 minutes per day',
  '1 hour per day',
  '2 hours or more per day',
]

export const ExerciseCriteria = [
  'Guest dogs can play in my backyard',
  'I go on 1 walk per day',
  'I go on 2 or more walks per day',
  'I take guest dogs hiking with me',
  'I take guest dogs to a local park',
]

export const ResidentPetCount = [
  '1 resident pet',
  '2 resident pets',
  '3 resident pets',
  '4 or more resident pets',
]

export const ResidentPetCriteria = [
  'I have a dog',
  'I have a wolf-hybrid',
  'I have a cat',
  'I have a hamster',
  'I have a guinea pig',
  'I have a reptile',
  'I have a fish',
  'I have a horse',
  'I have a chicken',
  'I have a pig',
  'I have a goat',
]

export const Medication = ['Yes', 'No']

export const MedicationCriteria = [
  'I am very comfortable giving pills by mouth',
  'I am very comfortable mixing pills into food',
  'I can give medication multiple times a day',
  'I am very comfortable giving nasal spray',
  'I am very comfortable giving injected medication',
]
