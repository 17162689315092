import * as style from './style.module.scss'
import * as Ariakit from '@ariakit/react'
import { useState } from 'react'
import * as React from 'react'

export default function SelectMenu(props) {
  return (
    <div className={style.wrapper}>
      <Ariakit.SelectProvider defaultValue='Please select'>
        <Ariakit.SelectLabel className={style.label}>
          {props.label}
        </Ariakit.SelectLabel>
        <Ariakit.Select className={style.select} />
        <Ariakit.SelectPopover gutter={4} sameWidth className={style.popover}>
          {props.list.map((value) => (
            <Ariakit.SelectItem
              key={value}
              value={value}
              className={style.selectItem}
            >
              <Ariakit.SelectItemCheck />
              {value}
            </Ariakit.SelectItem>
          ))}
        </Ariakit.SelectPopover>
      </Ariakit.SelectProvider>
    </div>
  )
}
