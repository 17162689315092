import CardListing from '../CardListing'
import Modal from '../Modal'
import Multiselect from '../Multiselect'
import Select from '../Select/index.js'
import Template from '../Template'
import * as list from './list.js'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import get from 'lodash/get'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'
import { usePopoverState, PopoverDisclosure } from 'reakit/Popover'
import { Tabbable } from 'reakit/Tabbable'

function RequestReservation() {
  const signUp = useDialogState({ baseId: 'signUp' })
  const reserve = useDialogState({ baseId: 'reserve' })

  return (
    <>
      <DialogDisclosure className={style.buttonSubmit} {...reserve}>
        Request reservation
      </DialogDisclosure>
      {/* if the user is logged in, Request reservation */}
      <Modal {...reserve}>
        <div className={style.modal}>
          <div>
            <div className={style.title}>
              <h2>Request stay</h2>
              <button
                className={style.buttonClose}
                onClick={() => reserve.hide()}
              >
                Close
              </button>
            </div>
            <form method='post'>
              <div className={style.block}>
                <h5>
                  <strong>Select the dogs that need a sitter</strong>
                </h5>
                <div className={style.inputGroupContainer}>
                  <div className={style.inputGroup}>
                    <input
                      type='checkbox'
                      id='dog1'
                      name='dog'
                      value='Bossy'
                    ></input>
                    <label htmlFor='dog1'>Bossy</label>
                  </div>
                  <div className={style.inputGroup}>
                    <input
                      type='checkbox'
                      id='dog2'
                      name='dog'
                      value='Aurelieus'
                    ></input>
                    <label htmlFor='dog2'>Aurelieus</label>
                  </div>
                </div>

                <div className={style.details}>
                  <h5>Reservation details</h5>
                  <div className={style.dates}>
                    <div>
                      <label>Drop-off date</label>
                      <input type='date' />
                    </div>
                    <div>
                      <label>Pick-up date</label>
                      <input type='date' />
                    </div>
                  </div>
                  <div className={style.textarea}>
                    <label>Your message</label>
                    <textarea
                      required
                      rows='4'
                      placeholder="This is a good place to introduce yourself and your dog, and give a few details about why you're looking for a sitter.  At least 200 words are recommended."
                    />
                  </div>
                </div>

                <div>
                  <Link className={style.button} to={'/message'}>
                    Send a message with your request
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* Otherwise, prompt them to login/sign-up */}
      <Modal {...signUp}>
        <div className={style.modal}>
          <div>
            <div className={style.title}>
              <h2>Join the list</h2>
              <button
                className={style.buttonClose}
                onClick={() => signUp.hide()}
              >
                Close
              </button>
            </div>
            <p>
              Sign ups are not open yet. 💔
              <br />
              <br />
              If you're interested in finding a local dog sitter, add your email
              address and we'll email you as soon as we're ready.
            </p>
            <form
              action='https://www.getdrip.com/forms/743233303/submissions'
              method='post'
              data-drip-embedded-form='743233303'
              id='drip-ef-743233303'
            >
              <div>
                <label htmlFor={'drip-email'}> Email address</label>
                <input type='email' id='drip-email' name='fields[email]' />
              </div>
              <div>
                <button
                  type='submit'
                  value='Sign Up'
                  data-drip-attribute='sign-up-button'
                >
                  Sign up for the waitlist
                </button>
              </div>
            </form>
          </div>
          <p className={style.note}>
            By signing up, you agree to receive emails from us. We’ll only send
            you emails related to your account, and we <strong>never</strong>{' '}
            sell personal data.
          </p>
        </div>
      </Modal>
    </>
  )
}

function EditProfile() {
  const editProfile = useDialogState({ baseId: 'editProfile' })

  return (
    <>
      <DialogDisclosure className={style.editLink} {...editProfile}>
        Edit profile
      </DialogDisclosure>
      {/* if the user is logged in, Request reservation */}

      <Modal {...editProfile}>
        <div className={style.modal}>
          <div>
            <div className={style.title}>
              <h2>Edit profile</h2>
              <button
                className={style.buttonClose}
                onClick={() => editProfile.hide()}
              >
                Close
              </button>
            </div>
            <p className={style.modalCopy}>
              Your profile is where dog owners learn about you and the services
              you offer.
            </p>
            <form method='post'>
              <div className={style.block}>
                <div className={style.profileHeadline}>
                  <label>
                    Profile headline <span className={style.required}>*</span>
                  </label>
                  <input
                    type='text'
                    placeholder='Use a business name or memorable headline'
                    required
                  ></input>
                </div>
                <div className={style.featuredImagesSection}>
                  <label htmlFor='featured_image_1'>
                    Featured images <span className={style.required}>*</span>
                  </label>
                  <p className={style.modalCopy}>
                    Upload up to 5 images that showcase your home, pets, and
                    services
                  </p>
                  <div className={style.featuredImages}>
                    <div className={style.featuredImage}>
                      <input name='featured_image_1' type='file' />
                    </div>
                    <div className={style.featuredImage}>
                      <input name='featured_image_2' type='file' />
                    </div>
                    <div className={style.featuredImage}>
                      <input name='featured_image_3' type='file' />
                    </div>
                    <div className={style.featuredImage}>
                      <input name='featured_image_4' type='file' />
                    </div>
                    <div className={style.featuredImage}>
                      <input name='featured_image_5' type='file' />
                    </div>
                  </div>
                </div>
                <div>
                  <div className={style.textarea}>
                    <label htmlFor='neighborhood'>
                      Describe your neighborhood and / or routine{' '}
                      <span className={style.required}>*</span>
                    </label>
                    <textarea
                      name='neighborhood'
                      rows='5'
                      placeholder='Use a business name or memorable headline'
                      required
                    />
                  </div>
                </div>
              </div>
              <div className={style.block}>
                <h3>About your stay</h3>
                <p className={style.modalCopy}>
                  The more details you provide, the more likely dog owners will
                  find you in search results.
                </p>
                <div className={style.optionList}>
                  <div className={style.optionGroup}>
                    <div className={style.option}>
                      <Select
                        label='How often is someone home?'
                        list={list.ScheduleEstimate}
                      />
                    </div>
                    <div className={style.option}>
                      <Multiselect
                        label="Any details you'd like to provide?"
                        optional='(Optional)'
                        list={list.HomeCriteria}
                      />
                    </div>
                  </div>
                  <div className={style.optionGroup}>
                    <div className={style.option}>
                      <Select
                        label='How many dogs will you host at once?'
                        list={list.DogCount}
                      />
                    </div>
                    <div className={style.option}>
                      <Multiselect
                        label='What kinds of dogs will you host?'
                        list={list.DogCriteria}
                      />
                    </div>
                  </div>
                  <div className={style.optionGroup}>
                    <div className={style.option}>
                      <Select
                        label='How much exercise can you provide?'
                        list={list.ExerciseEstimate}
                      />
                    </div>
                    <div className={style.option}>
                      <Multiselect
                        label='Exercise details'
                        optional='(Optional)'
                        list={list.ExerciseCriteria}
                      />
                    </div>
                  </div>
                  <div className={style.optionGroup}>
                    <div className={style.option}>
                      <Select
                        label='Do you have resident pets?'
                        list={list.ResidentPetCount}
                      />
                    </div>
                    <div className={style.option}>
                      <Multiselect
                        label='Resident pet details'
                        optional='(Select all that apply)'
                        list={list.ResidentPetCriteria}
                      />
                    </div>
                  </div>
                  <div className={style.optionGroup}>
                    <div className={style.option}>
                      <Select
                        label='Can you give medication?'
                        list={list.Medication}
                      />
                    </div>
                    <div className={style.option}>
                      <Multiselect
                        label='What kind?'
                        optional='(Select all that apply)'
                        list={list.MedicationCriteria}
                      />
                    </div>
                  </div>
                </div>
                <div className={style.block}>
                  <h3>About you</h3>
                  <div className={style.aboutYou}>
                    <div className={style.iconUpload}>
                      <span>Upload a photo of yourself</span>
                      <input type='file'></input>
                    </div>
                    <div className={style.aboutYouCopy}>
                      <div className={style.inputContainer}>
                        <label>
                          Your name, as you'd like it to appear on your profile
                        </label>
                        <input type='text'></input>
                      </div>

                      <div className={style.textarea}>
                        <label>
                          Describe yourself, and your experience with dogs
                        </label>
                        <textarea
                          required
                          rows='5'
                          placeholder='Describe your experience with dogs, why you got started with dog sitting, or what makes your services unique.'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.buttonContainer}>
                  <button
                    className={style.buttonClose}
                    onClick={() => editProfile.hide()}
                  >
                    Close
                  </button>
                  <button
                    className={style.buttonSubmit}
                    onClick={() => editProfile.hide()}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}

// function calculateRelationship(categories, targetCategories) {
//   let score = 0

//   targetCategories.forEach((targetCategory) => {
//     let count = categories.filter(
//       (category) => category === targetCategory
//     ).length
//     score += count
//   })

//   return score
// }

const PageListing = (props) => {
  const [bookedServices, setBookedServices] = useState(null)

  useEffect(() => {
    const getbookedServices = () => {
      if (typeof window !== 'undefined') {
        const services = window.localStorage.getItem('services') || '{}'
        const parsedServices = JSON.parse(services)
        for (const [key, value] of Object.entries(parsedServices)) {
          if (key === '/' + window.location.pathname.split('/')[1]) {
            return value
          }
        }
        return null
      }
      return null
    }

    const bookedServicesData = getbookedServices()
    setBookedServices(bookedServicesData)
  }, [])

  const listings = get(props, 'data.listings')
  const listing = props.data.listing
  const siteTitle = get(props, 'data.site.siteMetadata.title')

  const sortedListings = [...listings.edges].sort((a, b) => {
    return Math.random() - 0.5
  })

  return (
    <Template>
      <Helmet>
        <title>{`${listing.frontmatter.meta.title} - ${siteTitle}`}</title>
        <meta property='og:title' content={listing.frontmatter.meta.title} />
        <meta
          property='og:description'
          content={listing.frontmatter.meta.description}
        />
        {listing.frontmatter.image && (
          <meta property='og:image' content={listing.frontmatter.meta.image} />
        )}
        {listing.frontmatter.image && (
          <meta
            property='og:image'
            content={
              listing.frontmatter.image.childImageSharp.gatsbyImageData?.src
            }
          />
        )}
      </Helmet>

      {listing.frontmatter.image && (
        <div className={style.coverPhoto}>
          <GatsbyImage
            className={style.image}
            alt='Image'
            image={getImage(listing.frontmatter.image)}
          />
          <GatsbyImage
            alt='Image'
            className={style.image}
            image={getImage(listing.frontmatter.image2)}
          />
          <GatsbyImage
            alt='Image'
            className={style.image}
            image={getImage(listing.frontmatter.image3)}
          />
          <GatsbyImage
            alt='Image'
            className={style.image}
            image={getImage(listing.frontmatter.image4)}
          />
          <GatsbyImage
            alt='Image'
            className={style.image}
            image={getImage(listing.frontmatter.image5)}
          />
        </div>
      )}

      {bookedServices ? (
        <Tabbable className={style.alertWrapper} focusable>
          <div className={style.alert}>
            <div>
              {bookedServices.map((bookedServices) => (
                <p key={bookedServices.index}>
                  Your booking{' '}
                  <strong>
                    "
                    <Link to={bookedServices.index}>
                      {bookedServices.service.title}
                    </Link>
                    "
                  </strong>{' '}
                  is confirmed. Cancel or reschedule for free until{' '}
                  {new Intl.DateTimeFormat('en', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }).format(new Date(bookedServices.service.start))}{' '}
                  by contacting support.
                </p>
              ))}
            </div>
            <a
              className={style.button}
              href={'mailto:support+booking@doggos.com'}
            >
              Contact Support
            </a>
          </div>
        </Tabbable>
      ) : (
        ''
      )}
      {/* Logo, business name, category */}
      <div className={style.titleContainer}>
        {/* if loggedIn */}
        <EditProfile />
        {/* endIf */}

        <div className={style.title}>
          {listing.frontmatter.post_title && (
            <h1 className={style.h1}>{listing.frontmatter.post_title}</h1>
          )}
          <div className={style.tagContainer}>
            {listing.frontmatter.post_tag && (
              <div className={style.postTag}>
                {listing.frontmatter.post_tag}
              </div>
            )}
            {listing.frontmatter.state && (
              <div className={style.location}>
                <span>
                  {listing.frontmatter.city}, {listing.frontmatter.state}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={style.bookingContainer}>
        {/* if loggedIn */}
        {/* <div className={style.bookingContainerContent}>
          <div className={style.calendarContainer}>
            <Link to={'/dashboard'} className={style.editLink}>
              Edit availability
            </Link>
            <h2>Availability</h2>
            <p>
              Edit your calendar and rates on your dashboard to start accepting
              bookings
            </p>
          </div>
        </div> */}
        {/* endIf */}

        {/* if !loggedIn */}
        <div className={style.bookingContainerContent}>
          <div className={style.calendarContainer}>
            <h2>Availability</h2>
            <p>Oct 4, 2023 - Oct 12, 2023</p>
            <div className={style.calendar}>
              <calendar />
            </div>
          </div>
          <form className={style.checkoutForm}>
            <div className={style.checkoutItem}>
              <span>$42 x 8 nights (1 dog)</span> <span>$336</span>
            </div>
            <div className={style.checkoutItem}>
              <span>Platform fee</span> <span>$16</span>
            </div>
            <div className={`${style.checkoutItem} ${style.checkoutTotal}`}>
              <span>Total at checkout</span> <span>$352</span>
            </div>
            <div className={style.inputGroup}>
              <div className={style.row}>
                <div>
                  <label className={style.label}>Drop off</label>
                  <input className={style.input} type='date' />
                </div>
                <div>
                  <label className={style.label}>Pick up</label>
                  <input className={style.input} type='date' />
                </div>
              </div>
            </div>

            <RequestReservation />
            <p className={style.note}>You won't be charged yet.</p>
          </form>
        </div>
        {/* endIf */}
      </div>

      {/* if neighborhood */}
      <div className={style.neighborhood}>
        <h3>Neighborhood</h3>
        <div className={style.neighborhoodContainer}>
          <div className={style.map}>
            <img
              src='https://maps.geoapify.com/v1/staticmap?style=osm-carto&width=500&height=600&center=lonlat:-115.120216,36.201946&zoom=17&scaleFactor=2&apiKey=cd84c6383d8449f4bc7389926b627c6c
'
            ></img>
          </div>
          <div className={style.neighborhoodCopy}>
            <div>
              <h4>Henderson, Nevada</h4>
              <p>
                My neighborhood has a huge park very close to my house where I
                regularly go walking.
                <br />
                <br /> Lots of playtimes! I have toy buckets and balls, cookies,
                and treats...Or bring your own dog's toys and treats. I am high
                energy and like to have fun and play, Maybe even teach new
                tricks.
              </p>
            </div>
            <div className={style.callout}>
              <h5>What is my sitter's address?</h5>
              <p>
                Your sitter's exact location will be provided after booking a
                stay.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* endIf */}

      {/* if ThingsToKnow */}
      <div className={style.thingsToKnow}>
        <h3>Things to know</h3>
        <div className={style.thingsToKnowContainer}>
          <div>
            <ul>
              <li>3 resident pets</li>
              <li>Non-smoking household</li>
              <li>No children present</li>
              <li>Can administer injected medication</li>
              <li>Can administer oral medication</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>Can provide daily exercise</li>
              <li>Senior dog experience</li>
              <li>Specialty diet experience</li>
              <li>Puppy experience</li>
            </ul>
          </div>
        </div>
      </div>
      {/* endIf */}

      {/* Markdown */}
      <div className={style.profile}>
        <div className={style.icon}>
          {listing.frontmatter.icon && (
            <GatsbyImage
              alt='Icon'
              image={getImage(listing.frontmatter.icon)}
            />
          )}
        </div>
        <div className={style.content}>
          <h4>Meet {listing.frontmatter.sitter_first_name}</h4>
          <p className={style.bookingCount}>24 verified bookings</p>
          {listing.body && <MDXRenderer>{listing.body}</MDXRenderer>}

          {/* if !loggedIn */}
          <div className={style.buttonContainer}>
            <RequestReservation />
            <Link className={style.buttonSearch} to={'/search'}>
              Back to search results
            </Link>
          </div>
          {/* endIf */}
        </div>
      </div>

      <div className={style.reservationHistory}>
        <h3>Reservation history</h3>
        <div className={style.reservationHistoryContainer}>
          <div className={style.reservationHistoryItem}>
            <div className={style.timeline}></div>
            <div className={style.copyContainer}>
              <div>
                <div className={style.title}>Oct 4 - Oct 12, 2023</div>
                {/* this link is displayed conditionally, if the account owner is logged in */}
                <Link to={''}>View order details</Link>
              </div>
              <div className={style.statuses}>
                <div className={`${style.status} ${style.paid}`}>Completed</div>
              </div>
            </div>
          </div>
          <div className={style.reservationHistoryItem}>
            <div className={style.timeline}></div>
            <div className={style.copyContainer}>
              <div>
                <div className={style.title}>Sep 14 - Sep 20, 2023</div>
                {/* this link is displayed conditionally, if the account owner is logged in */}
                <Link to={''}>View order details</Link>
              </div>
              <div className={style.statuses}>
                <div className={`${style.status} ${style.canceled}`}>
                  Canceled by owner
                </div>
                <div className={`${style.status} ${style.canceled}`}>
                  Refunded
                </div>
              </div>
            </div>
          </div>
          <div className={style.reservationHistoryItem}>
            <div className={style.timeline}></div>
            <div className={style.copyContainer}>
              <div>
                <div className={style.title}>Sep 1 - Sep 3, 2023</div>
                {/* this link is displayed conditionally, if the account owner is logged in */}
                <Link to={''}>View order details</Link>
              </div>
              <div className={style.statuses}>
                <div className={`${style.status} ${style.canceled}`}>
                  Canceled by sitter
                </div>
                <div className={`${style.status} ${style.canceled}`}>
                  Refunded
                </div>
              </div>
            </div>
          </div>
          <div className={style.reservationHistoryItem}>
            <div className={style.timeline}></div>
            <div className={style.copyContainer}>
              <div>
                <div className={style.title}>Sep 1 - Sep 3, 2023</div>
                {/* if */}
                <Link to={''}>View order details</Link>
              </div>
              <div className={style.statuses}>
                <div className={`${style.status} ${style.canceled}`}>
                  Canceled by sitter
                </div>
                <div className={`${style.status} ${style.canceled}`}>
                  Refunded
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.callout}>
        <h5>✨ Looking for reviews?</h5>
        <p>
          We don't use public reviews on doggos.com.{' '}
          <Link to={'/how-reviews-work'}>Learn more</Link> about why.
        </p>
      </div>

      {/* Loop of Listings */}
      <div className={style.cardContainer}>
        {sortedListings
          .slice(0, sortedListings.length - Math.min(10, sortedListings.length))
          .map((edge, index) => {
            const listing = edge.node.frontmatter
            console.log(listing, sortedListings)
            return (
              <CardListing
                key={listing.id}
                link={`/${listing.id}/`}
                image={listing.image}
                date={listing.date}
                // services={listing.services}
                listing={listing}
                title={listing.post_title}
              />
            )
          })}
      </div>
    </Template>
  )
}

export default PageListing
