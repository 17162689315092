import * as style from './style.module.scss'
import {
  Select,
  SelectArrow,
  SelectItem,
  SelectItemCheck,
  SelectLabel,
  SelectPopover,
  SelectProvider,
} from '@ariakit/react'
import { useState } from 'react'
import * as React from 'react'

function renderValue(value) {
  if (value.length === 0) return 'Please select'
  if (value.length === 1) return value[0]
  return `${value.length} selected`
}

export default function Multiselect(props) {
  const [value, setValue] = useState(['Apple', 'Cake'])
  return (
    <div className={style.wrapper}>
      <SelectProvider value={value} setValue={setValue}>
        <SelectLabel className={style.label}>
          {props.label}
          <span className={style.optional}>{props.optional}</span>
        </SelectLabel>
        <Select className={style.select}>
          {renderValue(value)}
          <SelectArrow />
        </Select>
        <SelectPopover sameWidth unmountOnHide className={style.popover}>
          {props.list.map((value) => (
            <SelectItem key={value} value={value} className={style.selectItem}>
              <SelectItemCheck />
              {value}
            </SelectItem>
          ))}
        </SelectPopover>
      </SelectProvider>
    </div>
  )
}
