import GatsbyLink from 'gatsby-link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import OutboundLink from '../OutboundLink'
import * as style from './style.module.scss'

function Link({ link, ...props }) {
  if (link.startsWith('http')) {
    return <OutboundLink href={link}>{props.children}</OutboundLink>
  }
  return <GatsbyLink title={'View on doggos.com'} to={link} {...props} />
}

export default function CardListing(props) {
  const bookableServices =
    props.listing.services?.filter((service) => service.id) || []

  return (
    <div className={style.cardListing}>
      <Link
        className={style.linkWrapper}
        link={typeof props.link === 'string' ? props.link : props.link.pathname}
        state={props.link && props.link.state ? props.link.state : undefined}
      >
        <div className={style.flex}>
          {props.image && (
            <div className={style.image}>
              <GatsbyImage image={getImage(props.image)} alt="" />
            </div>
          )}
          <div className={style.text}>
            <div>
              {props.city && <div className={style.city}>{props.city}</div>}
              <h2 className={style.title}>{props.title}</h2>

              {/* {bookableServices.length > 0 && (
                <div className={style.onlineBooking}>Services Available</div>
              )} */}
            </div>

            {/* Display post tags */}
            <div className={style.tagContainer}>
              {props.listing.tags?.map((tag) => (
                <span key={tag} className={style.postTag}>
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
